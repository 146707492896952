h1 {
  color: pink;
  text-align: center;
  font-family: '8BITWONDERNominal';
   font-weight: normal;
   font-style: oblique;
}

h4 {
  font-style: oblique;
}

.anakin {
  height: 500px;
  width: 500px;
  margin: auto;
}

.anakin img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  border: 5px solid transparent;
  border-image: linear-gradient(to bottom right, #d1c2ff 0%, #c2ffff 25%, #d1ffc2 50%, #ffffc2 75%, #ffc2d1 100%);
  border-image-slice: 1;
  margin: 20px auto;
}

.topnav {
  background-color: #ff99cc;
  overflow: hidden;
  
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  border-style: solid;
  border-color: #ffb3d9;
  border-width: thin;
  text-decoration: none;
  font-size: 16px;
  
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ffcce6;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color:  #ff99cc;
  color: white;
}

ul.navie {
  list-style-type: none;
}

.App {
  text-align: center;
}

body {
  background-color: pink;
  font-family: Sans-Serif;
  line-height: 18px;
  text-align: center;
}

.App {
  background: #fff;
  -moz-box-shadow: 0 0 10px rgba(0,0,0,.3);
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,.3);
  box-shadow: 0 0 10px rgba(0,0,0,.3);
  margin: 16px auto;
  max-width: 960px;
  padding: 2.25%; /* 18px / 800px */
  width: 85%;
}

.projectcard {
    border: 2px solid mediumpurple;
    background-color: white;
    box-shadow: 0 8px 6px -6px black;
    width: 400px;
    height: 600px;
    text-align: center;
    display: inline-block;
    margin-bottom: 25px;
    overflow: hidden;
    /* contain: strict; */
    
}

.dogimg {
  width: 31vw;
  height: 17vw;
  background-image: url("./images/dogtwitter.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
 
}

.projectdescription {
  text-align: center;
}

li {
  list-style-type: none;
}
ul li:before {
  content: "\2665 ";
  color: #ff99cc;
}

h4 + ul {
  margin-top: -15px;
}

.spaceimg {
  width: 30vw;
  height: 15vw;
  background-image: url("./images/spacethings.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
 
}

.corgiimg {
  width: 30vw;
  height: 15vw;
  background-image: url("./images/corgitama.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
}

.googleimg {
  width: 30vw;
  height: 15vw;
  background-image: url("./images/google.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
}

.twitterimg {
  width: 30vw;
  height: 15vw;
  background-image: url("./images/twitter.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
}

.netfliximg {
  width: 30vw;
  height: 15vw;
  background-image: url("./images/netflix.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
}

.passportimg {
  width: 30vw;
  height: 15vw;
  background-image: url("./images/passport.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
}

.resume {
  width: 63vw;
  height: 75vw;
  background-image: url("./images/resume.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  text-align: center;
  border: 2px solid hotpink;
  background-color: white;
  box-shadow: 0 8px 6px -6px black;
}

.badges {
    height: 100%;
    width: 100%;
    display: flex;
}

.git {
  width: 15%;
  margin-left: 45px;
  float: right;
  
}
.link {
  width: 15%;
}

:root {
  --mainColor: pink;
}

.projectl {
  color:hotpink;
  text-decoration: none;
  margin-right: 15px;
  background:
     linear-gradient(
       to bottom, var(--mainColor) 0%,
       var(--mainColor) 100%
     );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 4px;
  transition: background-size .2s;
  font-style: oblique;
}


.projectl:hover {
  background-size: 4px 50px;
}

.desc {
  font-style: oblique;
  font-family: "Lucida Console", "Courier New", monospace;
}

.abtme {
  text-align: center;
  font-style: oblique;
  border: 5px solid transparent;
  border-image: linear-gradient(to bottom right, #d1c2ff 0%, #c2ffff 25%, #d1ffc2 50%, #ffffc2 75%, #ffc2d1 100%);
  border-image-slice: 1;
  margin: 20px auto;
  background: white;
  transition: background-color 2s ease-out 100ms
  }
  
.abtme:hover {
  background-color: #ffe6ea;
    
}

.me {
  font-family: '8BITWONDERNominal';
   font-weight: normal;
   font-style: oblique;
  border-style: solid;
  border-width: .125em 0;
  margin: 0 .25em;
  padding: .5em .375em;
  position: relative;
  z-index: 1;
}

  .me:before {
      background-color: inherit;
      border: inherit;
      border-width: 0 .125em;
      bottom: .125em;
      content: '';
      left: -.25em;
      pointer-events: none;
      position: absolute;
      top: .125em;
      right: -.25em;
      z-index: -1;
  }

  .me:after {
      border: inherit;
      border-width: 0 .125em;
      bottom: 0;
      content: '';
      left: -.125em;
      position: absolute;
      top: 0;
      right: -.125em;
      z-index: -2;
  }

  .contactcard {
    border: 2px solid mediumpurple;
    background-color: white;
    box-shadow: 0 8px 6px -6px black;
    width: 400px;
    height: 400px;
    text-align: center;
    display: inline-block;
    margin-bottom: 25px;
    font-style: oblique;
    overflow: hidden;
    
} 

.emaillink {
  background-color: #ffcce6;
  font-family: Arial, Helvetica, sans-serif;
  border: 2px solid hotpink;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
}

.emaillink:hover {
    background-color: #ffb3da;
    font-family: Arial, Helvetica, sans-serif;
    border: 2px solid hotpink;
    border-radius: 8px;
  }

 .emaillink:visited{
   color: black;
 } 

 .div-wrapper {
  position: relative;
  height: 300px;
  width: 300px;
}

.div-wrapper img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 400px;
  margin-bottom: 40px;
}


#projects > div {
  margin: 10px;
  
}

